import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { serverToken, shopCaerosaul } from '../Constants/urls';

const MyCarousel = () => {
    const [carouselItems, setCarouselItems] = useState([]);

    useEffect(() => {
        const fetchCarouselData = async () => {
            try {
                const response = await fetch(shopCaerosaul, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Token ${serverToken}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                setCarouselItems(data.results.map((e) => e.image));
            } catch (error) {
                console.error("Error fetching carousel data:", error);
            }
        };

        fetchCarouselData();
    }, []);
    return (
        <div className="container">
            <div id="productCarousel" className="carousel slide mb-5 h-300" data-bs-ride="carousel">
                <div className="carousel-inner">
                    {carouselItems.map((item, index) => (
                        <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
                            <img src={item} className="d-block w-100 rounded" alt={item.title} />
                            <div className="carousel-caption d-none d-md-block">
                                <h5>{item.title}</h5>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    ))}

                </div>

                <a className="carousel-control-prev" href="#productCarousel" role="button" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#productCarousel" role="button" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>
        </div>
    );
};

export default MyCarousel;
